<template>
  <div v-loading="loading" class="table-area" style="height:10px">
    <el-row class="table-top" style="height:10px">
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <h4 class="heading">Settings</h4>
      </el-col>
      <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
        <div class="search-wrapper"></div>
      </el-col>
      <el-col :xs="24" :sm="6" :md="6" :lg="6" :xl="6">
        <div class="btn-wrapper">
          <!-- <el-button type="primary" @click="handleCreate()">Update</el-button> -->

          <el-button type="primary" @click="handleTradeClose()">Trade Close</el-button>
          <!-- <el-button type="primary" @click="handleBlockScript()">Block Script</el-button> -->
        </div>
      </el-col>
    </el-row>
    <div>
      <el-card class="setting_wrapper">
        <div class="table_wrapper">
          <table class="table" border="1" width="100%">
            <tbody>
              <!-- <tr>
              <th>
                <p>Execute Pending Orders At Ltp</p>
              </th>
              <td>
                <p v-if="viewData.execute_pending_orders_at_ltp === 'true'">
                  Yes
                </p>
                <p v-else>No</p>
              </td>
              </tr>
              <tr>
                <th><p>Trade Equity As Units :</p></th>
                <td>
                  <p v-if="viewData.first_contract_only === 'true'">Yes</p>
                <p v-else>(not set)</p>
                </td>
              </tr>
              <tr>
                <th><p>Traded By Admin Message :</p></th>
                <td>
                   <p v-if="viewData.traded_by_admin_message === 'true'">Yes</p>
                <p v-else>No</p>
                </td>
              </tr> -->
              <tr>
                <th><p> Sound play in trade :</p></th>
                <td>
                   <p v-if="viewData.sound_play_in_trade == 'true'">Yes</p>
                <p v-else>No</p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </el-card>
    </div>

    <BlockScriptDialog
      :dialog-visible.sync="dialogVisibleBlockScript"
      :dialog-type="dialogTypeBlockScript"
      :form-data="formData"
      :form-errors.sync="formErrors"
      :listScriptDataMcx="listScriptDataMcx"
      :listScriptDataNse="listScriptDataNse"
      :listScriptDataOth="listScriptDataOth"
      @getChildFormData="handleChildBlockScript($event)"
      @childClose="handleBlockScriptClose()"
    />
    <CloseTradeDialog
      :dialog-visible.sync="dialogVisibleCloseTrade"
      :dialog-type="dialogTypeCloseTrade"
      :form-data="formData"
      :form-errors.sync="formErrors"
      :listClients="listClients"
      @getChildFormData="handleChildCloseTrade($event)"
      @childClose="handleBlockCloseTrade()"
    />
  </div>
</template>

<script>
import { fetch, blockScript, getClient, CloseTrade } from "@/api/settings";
import moment from "moment";
import BlockScriptDialog from "@/views/settings/components/BlockScriptDialog.vue";
import CloseTradeDialog from "@/views/settings/components/CloseTradeDialog.vue";
import { fetchStockList } from "@/api/market-watch";
import { mapGetters } from "vuex";



export default {
  name: "PendingOrders",
  components: {
    BlockScriptDialog,
    CloseTradeDialog
  },
  data() {
    return {
      loading: false,
      viewData: {},
      dialogVisibleBlockScript: false,
      dialogTypeBlockScript: "create",
      dialogVisibleCloseTrade: false,
      dialogTypeCloseTrade: "Trade close",
      listScripts: {},
      formData: {},
      formErrors: [],
      listClients:[],
      listScriptDataTempMcxStockList: null,
      listScriptDataMcx: {
        data: {
          item: [],
        },
      },
      listScriptDataTempNseStockList: null,
      listScriptDataNse: {
        data: {
          item: [],
        },
      },
      listScriptDataTempOthStockList: null,
      listScriptDataOth: {
        data: {
          item: [],
        },
      }

    };
  },
  computed: {
    ...mapGetters(["getRoleSlug"]),
  },
  created() {
    this.getSettingsDetails();
    //this.getScriptList();
    //this.getClientList()
  },
  methods: {
    handleBlockScript() {
      this.dialogVisibleBlockScript = true;
      this.dialogTypeBlockScript = "create"
      this.listScriptDataMcx.data.item = this.listScriptDataTempMcxStockList
      this.listScriptDataNse.data.item = this.listScriptDataTempNseStockList
      this.listScriptDataOth.data.item = this.listScriptDataTempOthStockList
    },
    getScriptList() {
      fetchStockList().then((response) => {
        this.listScriptDataTempMcxStockList  = response.data.data.mcxStockList;
        this.listScriptDataTempNseStockList  = response.data.data.nseStockList;
        this.listScriptDataTempOthStockList  = response.data.data.othStockList;
        console.log(this.listScripts)
      });
    },

    getClientList() {
      getClient().then((response) => {
        this.listClients  = response.data.data;
        console.log("listClients : ",this.listClients)
      });
    },
    handleBlockScriptClose() {
      this.dialogVisibleBlockScript = false;
    },
    handleBlockCloseTrade() {
      this.dialogVisibleCloseTrade = false;
    },
    handleChildBlockScript(data) {
      this.loading
      let dataPost = {
        ...data
      }
      console.log(dataPost);
      blockScript(dataPost).then((response) => {
        if (response.data.success === true) {
                console.log(response.data)
                this.flashSuccess(response.data.message);
                this.loading = false;
                this.dialogVisibleBlockScript = false;
                this.getScriptList();
              } else {
                this.formErrors = this.validationErrors(
                  response.data.message
                );
          }
        this.loading = false;
      })
      .catch((err) => {
              console.log(err);
      });
    },

    handleChildCloseTrade(data) {
      this.loading
      let dataPost = {
        ...data
      }
      console.log(dataPost);
      CloseTrade(dataPost).then((response) => {
        if (response.data.success === true) {
                console.log(response.data)
                this.flashSuccess(response.data.message);
                this.loading = false;
                this.dialogVisibleCloseTrade = false;
              } else {
                this.formErrors = this.validationErrors(
                  response.data.message
                );
          }
        this.loading = false;
      })
      .catch((err) => {
              console.log(err);
      });
    },

    formateTime: function (time) {
      return moment(time, "HH:mm:ss").format("hh:mm A");
    },
    handleCreate() {
      return this.$router.push("/admin/settings-update");
    },
    handleTradeClose() {
      this.dialogVisibleCloseTrade = true
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getSettingsDetails() {
      this.loading = true;
      fetch().then((response) => {
        this.viewData = response.data.data;
        // this.filters2(this.viewData.mcx_start_trading_at)
        console.log(this.viewData);
        this.loading = false;
      });
    },
  },
};
</script>
