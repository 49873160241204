<template>
    <div v-loading="loading">
      <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        :before-close="handleCloseDialog"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
      >
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
          <el-row :gutter="20">
             <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                <el-form-item
                  :error="formErrors.admin"
                  label="Client"
                  prop="client"
                  :label-width="formLabelWidth"
                >
                  <el-select v-model="formData.client_id"  placeholder="Select">
                    <el-option :value=null> All</el-option>
                    <el-option
                       v-for="item in listDataDropdownClients"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
          </el-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseDialog()">Cancel</el-button>
          <el-button type="info" @click="sendChildFormData()">Save</el-button>
        </span>
      </el-dialog>
    </div>
  </template>
  
  <script>
  //import { requiredRule, numberRule } from "@/rules/all-rules";
  import { fetchClientList } from "@/api/market-watch";
  import { mapGetters } from "vuex";
  //import { fetchAllAdminList, adminClients } from "@/api/super-admin/admin";
  //import getRoleSlug from "@/store/app.js";
  //import { fetchAdminAllowedStockList } from "@/api/market-watch";
  
  
  
  
  export default {
    name: "AddEditDialog",
    components: {},
    props: {
      dialogVisible: {
        type: Boolean,
        default: false,
      },
      dialogType: {
        type: String,
        default: "",
      },
      formData: {
        type: Object,
        default: function () {
          return {};
        },
      },
      listDataDropdownClients: {
        type: Array,
        default: function () {
          return [];
        },
      },
      formErrors: {
        type: Array,
        default: function () {
          return [];
        },
      },
    },
    data() {
      return {
        loading: false,
        formRules: {},
        formLabelWidth: "120px",
        listClients: {},
      };
    },
    computed: {
       ...mapGetters(["getRoleSlug"]),
      dialogTitle() {
        return this.dialogType;
      },
    },
    created() {

      this.getClientList()
    },
    methods: {
      getClientList() {
        fetchClientList().then((response) => {
          this.listDataDropdownClients = response.data.data;
        });
      },
      handleCloseDialog() {
        this.$emit("childClose");
      },
      sendChildFormData() {
        this.loading = true;
        this.$refs.refForm.validate((valid) => {
          if (valid) {
            this.$emit("getChildFormData", this.formData);
            this.loading = false;
          }
        });
      },
    },
  };
  </script>
  