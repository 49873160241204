<template>
<div v-loading="loading">
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" :before-close="handleCloseDialog" :close-on-click-modal="false" :close-on-press-escape="false">
            <el-alert
                title="Unchecked will become unblock script after click Block/Unblock button"
                type="info">
            </el-alert>
            <br>
          <el-button style="float:right"
            type="primary"
            @click="handleBlockScript"
            >Block/Unblock</el-button
          >
            <!-- v-if="this.multipleSelectedIds.length" -->
          <br>
          <br>
          <br>
           <el-tabs type="border-card" @tab-click="handleTabClick" v-model="currentTab">
            <el-tab-pane label="MCX" name="mcx">
                 <Table style="margin-top:50px;"
                    tableKey="listScriptDataSet"
                    :tableConfig="config"
                    :tableData="listScriptDataMcx"
                    :tableQuery="listQuery"
                    :tableActionShowVisibility="false"
                    :tableActionVisibility="false"
                    :tableActionViewDetailsVisibility="true"
                    :tableActionEditVisibility="false"
                    :tableActionDeleteVisibility="false"
                    :showSummary="false"
                    :tableCheckBoxVisibility="true"
                    :tableLoadingStatus.sync="loading"
                    @multipleSelection="handleMultipleSelection($event)"
                />
            </el-tab-pane>
            <el-tab-pane label="NSE" name="nse">
                 <Table style="margin-top:50px;"
                    tableKey="listScriptDataSet"
                    :tableConfig="config"
                    :tableData="listScriptDataNse"
                    :tableQuery="listQuery"
                    :tableActionShowVisibility="false"
                    :tableActionVisibility="false"
                    :tableActionViewDetailsVisibility="true"
                    :tableActionEditVisibility="false"
                    :tableActionDeleteVisibility="false"
                    :showSummary="false"
                    :tableCheckBoxVisibility="true"
                    :tableLoadingStatus.sync="loading"
                    @multipleSelection="handleMultipleSelection($event)"
                />
            </el-tab-pane>
            <!-- <el-tab-pane label="OTH" name="oth">
                 <Table style="margin-top:50px;"
                    tableKey="listScriptDataSet"
                    :tableConfig="config"
                    :tableData="listScriptDataOth"
                    :tableQuery="listQuery"
                    :tableActionShowVisibility="false"
                    :tableActionVisibility="false"
                    :tableActionViewDetailsVisibility="true"
                    :tableActionEditVisibility="false"
                    :tableActionDeleteVisibility="false"
                    :showSummary="false"
                    :tableCheckBoxVisibility="true"
                    :tableLoadingStatus.sync="loading"
                    @multipleSelection="handleMultipleSelection($event)"
                />
            </el-tab-pane> -->
           </el-tabs>
       
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleCloseDialog()">
          Cancel
        </el-button>
            <!-- <el-button type="primary" @click="sendChildFormData()">Save</el-button> -->
        </span>
    </el-dialog>
</div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
import Table from "@/components/Table/Table.vue";
import { _ } from "vue-underscore";


export default {
    name: "AddEditDialog",
    components: {
        Table
    },
    props: {
        dialogVisible: {
            type: Boolean,
            default: false,
        },
        dialogType: {
            type: String,
            default: "",
        },
        listScriptDataMcx: {
            type: Object,
            default: function () {
                return {};
            },
        },
        listScriptDataNse: {
            type: Object,
            default: function () {
                return {};
            },
        },
        listScriptDataOth: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    data() {
        return {
            loading: false,
            formRules: {},
            formLabelWidth: "120px",
            config: [
                {
                label: "Name",
                prop: "name",
                width: "",
                className: "blueFont",
                },
                {
                label: "Blocked Status",
                prop: "is_blocked",
                width: "",
                className: "blueFont",
                },
            ],
            listQuery: {
                page: 1,
                limit: 20,
                search: "",
                search_type: "",
                searchJoin: "or",
                orderBy: "created_at",
                sortedBy: "desc",
            },
            multipleSelectedIds: [],
            currentTab: "mcx",
            type: '',
        };
    },
    computed: {
        dialogTitle() {
            return this.dialogType === "create" ? 'Block Script' : '';
        },
    },
    created() {
        /**
         * Including  Of Validation Rule
         */
        this.formRules = {
            username: requiredRule("username"),
            password: requiredRule("password"),
        };
    },
    methods: {
        handleMultipleSelection(data) {
            console.log(data);
            let ids = [];
            _(data).each(function (value) {
                ids.push(value.stock_id);
            });

            this.multipleSelectedIds = ids;
            console.log(this.multipleSelectedIds);
        },
        handleCloseDialog() {
            this.$emit("childClose");
        },
        sendChildFormData() {
            this.loading = true;
            this.$refs.refForm.validate((valid) => {
                if (valid) {
                    // WHEN EDIT MERGE NEW PARAMETERS
                    if (this.dialogType === "edit") {
                        this.formData = Object.assign(this.formData, {
                            dialog_type: this.dialogType,
                        });
                    }
                    this.$emit("getChildFormData", this.formData);
                    this.loading = false;
                }
            });
        },
        handleTabClick(tab) {
            console.log(tab.index);
            if (tab.index == 0) {
                this.type = 'mcx'
                console.log(this.type)
            } else if (tab.index == 1) {
                this.type = 'nse'
                console.log(this.type)
            } else if (tab.index == 2) {
                this.type = 'oth'
                console.log(this.type)
            }
        },
        handleBlockScript() {
            let dataEmit = {
                ids : this.multipleSelectedIds,
                type : this.type ? this.type : 'mcx'
            }
            console.log(dataEmit);
            this.$emit('getChildFormData', dataEmit);
        }
    },
};
</script>
